/**
 * =============================================================================
 * ************   Appbar 应用栏   ************
 * =============================================================================
 */

.mdui-appbar {
  width: 100%;
  z-index: @z-index-appbar;
  .mdui-shadow(4);
}

/* appbar 固定在顶部时，为 body 添加 padding-top，避免 appbar 覆盖页面内容 */
.mdui-appbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

/* 含工具栏 */
.mdui-appbar-with-toolbar {
  padding-top: @appbar-height-xs-portrait;

  // 平板和桌面
  @media (min-width: @screen-sm-min) {
    padding-top: @appbar-height-sm;
  }

  // 手机横屏
  @media (orientation: landscape) and (max-width: @screen-xs-max-landscape) {
    padding-top: @appbar-height-xs-landscape;
  }
}

/* 含 Tab */
.mdui-appbar-with-tab {
  padding-top: 48px;
}

/* 含大 Tab  */
.mdui-appbar-with-tab-larger {
  padding-top: 72px;
}

/* 含工具栏和 Tab */
.mdui-appbar-with-toolbar.mdui-appbar-with-tab {
  padding-top: @appbar-height-xs-portrait + 48px;

  // 平板和桌面
  @media (min-width: @screen-sm-min) {
    padding-top: @appbar-height-sm + 48px;
  }

  // 手机横屏
  @media (orientation: landscape) and (max-width: @screen-xs-max-landscape) {
    padding-top: @appbar-height-xs-landscape + 48px;
  }
}

/* 含工具栏和大 Tab */
.mdui-appbar-with-toolbar.mdui-appbar-with-tab-larger {
  padding-top: @appbar-height-xs-portrait + 72px;

  // 平板和桌面
  @media (min-width: @screen-sm-min) {
    padding-top: @appbar-height-sm + 72px;
  }

  // 手机横屏
  @media (orientation : landscape) and (max-width: @screen-xs-max-landscape) {
    padding-top: @appbar-height-xs-landscape + 72px;
  }
}


/**
 * =============================================================================
 * ************   Appbar dark   ************
 * =============================================================================
 */
.mdui-theme-layout-dark when (@globalLayoutDark = true) {
  .mdui-appbar {
    &>[class*="mdui-color-"]:not(.mdui-color-transparent) {
      background-color: @layout-dark-color-2 !important;
      color: #fff !important;
    }
  }
}
